<template>
  <transition name="component-fade" mode="in-out">
    <span
      class="flash-message"
      :class="{
        'flash-message-error': error,
        'flash-message-success': !error,
      }"
      v-if="message"
      @click.prevent="message = ''"
    >
      {{ message }}
    </span>
  </transition>
</template>

<script>
export default {
  props: ["data_message"],
  data() {
    return {
      message: "",
      error: false,
    };
  },
  methods: {
    flash(message, error = false) {
      this.message = message;
      this.error = error;
      window.setTimeout(() => {
        this.message = "";
        this.error = false;
      }, 5000);
    },
  },
  mounted() {
    this.eventBus.on("flashMessage", (data) =>
      this.flash(data.message, data.error)
    );
    if (this.data_message) {
      this.flash(this.data_message);
    }
  },
};
</script>
